.ant-carousel .slick-slide {
	text-align: center;

	overflow: hidden;
}

.ant-carousel .slick-slide h3 {
	color: #fff;
}

.slide {
	position: relative;
}
.infoSlide {
	position: absolute;
	/* left: 0; */
	top: 50%;
	z-index: 9;
	text-align: left;
	transform: translateY(-50%);
}

.titleSlide {
	color: #fff;
	font-size: 40px;
	font-weight: bold;
	line-height: 45px;
	margin-bottom: 40px;
}

.btnSlide a {
	display: inline-block;
	padding: 0px 40px;
	color: #fff;
	text-align: center;
	font-size: 18px;
	border: 2px solid #fff;
	font-weight: 500;
	line-height: 40px;
	border-radius: 5px;
	transition: all 0.2s ease;
}
.btnSlide a:hover {
	background: #fff;
	color: #000;
}
