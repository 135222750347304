.base-header {
	position: sticky;
	top: 0;
	z-index: 1;
	width: 100%;
	overflow: hidden;
	padding: 0 20px;

	.ant-col:first-child {
		margin-right: auto;
	}
}
