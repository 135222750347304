.simple-info {
	margin-bottom: 10px;
	margin-right: 10px;
}
.simple-info h6 {
	font-weight: normal;
	color: rgba(0, 0, 0, 0.45);
	font-size: 13px;
}
.tip {
	vertical-align: top;
}
