.analytics-wrapper {
	margin: 10px;
	background-color: #fff;
	padding: 10px;
}

.analytics-tools {
	margin-bottom: 10px;
}

.analytics-chart {
	width: 100%;
	height: 500px;
	margin-bottom: 20px;
}

.analytics-filter-app {
	width: 200px;
}
