footer {
	background: #fff;
	padding: 38px 0;
	border-top: 1px solid #fff;
	overflow: hidden;
	padding: 40px 0;
}
.footer-column {
	text-align: left;
}
.footer3 .widget-title {
	text-transform: uppercase;
	margin-bottom: 30px;
	font-size: 16px;
	line-height: 26px;
	font-weight: 700;
}
.footer3 .footer-column ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.footer3 .footer-column ul li {
	margin-bottom: 0;
}
.footer3 .footer-column a,
.footer3 .footer-column li {
	font-size: 14px;
	line-height: 25px;
	opacity: 0.9;
	color: #000;
}
footer .social li a {
	margin: 0 3px;
}
.footer3 .social li a {
	opacity: 1;
	margin-left: 0 !important;
	margin-right: 15px !important;
}
footer .nav-footer {
	padding: 5px 0;
}
footer nav li {
	margin: 0 20px 0 0 !important;
}
footer nav li a {
	font-size: 13px;
	line-height: 22px;
	margin: 2px 0;
	text-transform: none;
	font-weight: 700;
	color: #6b7179;
	letter-spacing: 0.13px;
}
footer .infos p {
	margin-bottom: 10px;
	font-size: 13px;
}
.footer3 .download .ios {
	margin-bottom: 15px;
}

/* End Footer style */
