.allteams {
	padding: 0;
	li {
		display: inline-block;
		cursor: pointer;
	}
}

.team-selection {
	text-align: center;
	padding-top: 50px;
}
