.header {
	position: sticky;
	top: 0;
	z-index: 9;
	border-bottom: 1px solid #f1f1f1;
}
.navbar {
	background: #fff;
	padding-left: 30px;
	padding-right: 30px;
}

.nav-link {
	color: #000;
	font-size: 15px;
	font-weight: 500;
}

.nav-link:hover {
	color: #000;
}
.navbar-expand-lg .navbar-nav .nav-link {
	padding-left: 1rem;
	padding-right: 1rem;
}

@media (max-width: 992px) {
	.navbar {
		padding-left: 15px;
		padding-right: 15px;
		flex-direction: row-reverse;
	}
	button.navbar-toggler {
		border: none;
	}
	.navbar-toggler:focus,
	.navbar-toggler:hover {
		outline: none;
	}
	.ant-input-search input,
	.ant-input-search input:focus {
		width: 100%;
	}
}
