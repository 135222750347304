li {
	&.team-item {
		width: 200px;
		margin-bottom: 20px;
		text-align: center;

		h4 {
			font-size: 16px;
			margin-top: 10px;
		}

		.ant-avatar-string {
			font-size: 60px;
		}
	}
}
